<template>
    <v-layout>
        <v-flex
            xs12
            md8
            offset-md2>
            <v-card>
                <v-toolbar
                    dark
                    color="primary darken-4">
                    <v-toolbar-title class="white--text">
                        Edit {{ dealer.name }}
                    </v-toolbar-title>
                    <v-spacer />
                </v-toolbar>
                <v-card-text>
                    <v-container
                        fluid
                        grid-list-md>
                        <v-form
                            ref="form"
                            v-model="valid">
                            <v-layout row>
                                <v-flex xs12>
                                    <v-text-field
                                        v-model="dealer.name"
                                        name="name"
                                        label="Dealer Name"
                                        dark
                                        prepend-icon="business"
                                        required />
                                </v-flex>
                            </v-layout>
                            <v-layout row>
                                <v-flex xs12>
                                    <v-text-field
                                        v-model="dealer.address"
                                        label="Dealer Address"
                                        prepend-icon="business"
                                        required />
                                </v-flex>
                            </v-layout>
                            <v-layout row>
                                <v-flex xs12>
                                    <v-text-field
                                        v-model="dealer.city"
                                        label="City"
                                        prepend-icon="business"
                                        required />
                                </v-flex>
                            </v-layout>
                            <v-layout row>
                                <v-flex xs12>
                                    <v-text-field
                                        v-model="dealer.state"
                                        label="State"
                                        prepend-icon="business"
                                        required />
                                </v-flex>
                            </v-layout>
                            <v-layout row>
                                <v-flex xs12>
                                    <v-text-field
                                        v-model="dealer.zip"
                                        label="Zip"
                                        prepend-icon="business"
                                        required />
                                </v-flex>
                            </v-layout>
                            <v-layout row>
                                <v-flex xs12>
                                    <v-text-field
                                        v-model="dealer.country_code"
                                        label="Country Code"
                                        prepend-icon="business"
                                        required />
                                </v-flex>
                            </v-layout>
                            <v-layout row>
                                <v-flex xs12>
                                    <v-text-field
                                        v-model="dealer.site_base_url"
                                        name="homepage URL"
                                        label="Homepage URL"
                                        dark
                                        prepend-icon="home"
                                        required />
                                </v-flex>
                            </v-layout>
                            <v-layout row>
                                <v-flex xs12>
                                    <v-select
                                        v-model="dealer.agency_id"
                                        :items="agencies"
                                        item-text="name"
                                        item-value="id"
                                        :loading="loadingAgencies"
                                        label="Agency"
                                        dark
                                        prepend-icon="web"
                                        autocomplete
                                        required />
                                </v-flex>
                            </v-layout>
                            <v-layout row>
                                <v-flex xs12>
                                    <v-select
                                        v-model="dealer.site_provider_id"
                                        :items="siteProviders"
                                        item-text="name"
                                        item-value="id"
                                        :loading="loadingSiteProviders"
                                        label="Website Provider"
                                        dark
                                        prepend-icon="web"
                                        autocomplete
                                        required />
                                </v-flex>
                            </v-layout>
                            <v-btn
                                :disabled="!valid"
                                @click="submit()">
                                submit
                            </v-btn>
                            <v-btn @click="editAgencyInputs = false">
                                cancel
                            </v-btn>
                        </v-form>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
    import HTTP from '../../http';
    import ApiQuery from '../../api-query';

    export default {
        title: 'Edit Dealer',
        data() {
            return {
                dealer: [],
                valid: false,
                siteProviders: [],
                agencies: [],
                loadingSiteProviders: true,
                loadingAgencies: true,
                errors: [],
            };
        },
        mounted() {
            this.getDealerData();

            (new ApiQuery('site_providers'))
                .setPageSize(1000)
                .get()
                .then(response => {
                    this.siteProviders = response.data.data;
                    this.loadingSiteProviders = false;
                })
                .catch(e => {
                    console.log(e);
                });

            (new ApiQuery('agencies'))
                .setPageSize(1000)
                .get()
                .then(response => {
                    this.agencies = response.data.data;
                    this.loadingAgencies = false;
                })
                .catch(e => {
                    console.log(e);
                });

        },
        methods: {
            submit() {
                (new HTTP)
                    .put(
                        '/dealers/' + this.$route.params.dealer_id,
                        {
                            agency_id: this.dealer.agency_id,
                            name: this.dealer.name,
                            site_base_url: this.dealer.site_base_url,
                            site_provider_id: this.dealer.site_provider_id,
                            address: this.dealer.address,
                            city: this.dealer.city,
                            state: this.dealer.state,
                            zip: this.dealer.zip,
                            country_code: this.dealer.country_code
                        }
                    )
                    .then(() => {
                        this.$flash('Dealer updated successfully!', 'green');
                        //todo fix this router push, it's currently pushing to dashboard
                        this.$router.push('dealers/' + this.$route.params.dealer_id);
                    })
                    .catch(e => {
                        this.$flash('Error!', 'red');
                        console.log(e);
                    });
            },
            getDealerData() {
                this.dealer = [];

                (new ApiQuery('dealers/' + this.$route.params.dealer_id))
                    .get()
                    .then(response => {
                        this.dealer = response.data.data;
                    })
                    .catch(e => {
                        console.log(e);
                    });
            },
        }
    };
</script>
